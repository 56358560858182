
// En lui donnant un CSSContainerRule, cette classe va générer une pagination en fonction du nombre de page total et du nombre de page à afficher.
class Pagination {
    constructor(containerPagination, params = {}) {
        this.container = containerPagination;
        this.containerInfo = null;
        this.containerPagination = null;
        this.nbPageToDisplay = 5;
        this.page = 1;
        this.nbPageTotal = 0;
        this.pageSize = 10;
        this.btnPrev = null;
        this.btnNext = null;
        this.results = 0;

        if (params.pageSize && params.pageSize > 0) {
            this.pageSize = params.pageSize;
        }

        if (params.nbPageToDisplay && params.nbPageToDisplay > 1) {
            this.nbPageToDisplay = params.nbPageToDisplay;
        }

        if (params.page && params.page > 0) {
            this.page = params.page;
        }

        if (params.nbPageTotal && params.nbPageTotal >= 0) {
            this.nbPageTotal = params.nbPageTotal;
        }

        if (params.results && params.results >= 0) {
            this.results = params.results;
        }

        this.constRender();
    }
    setNbPageTotal(nbPageTotal){
        this.nbPageTotal = nbPageTotal;
        this.render();
    }
    setPageSize(pageSize){
        this.pageSize = pageSize;
        this.render();
    }
    setPage(page){
        if (page < 1) {
            page = 1;
        }
        page = parseInt(page);
        this.page = page;
        // this.render();
    }
    setTotalResults(results){
        this.results = results;
        this.nbPageTotal = Math.ceil(results / this.pageSize);
        this.render();
    }
    getNbPageTotal(){
        return this.nbPageTotal;
    }
    getPageSize(){
        return this.pageSize;
    }
    getPage(){
        return this.page;
    }
    event(){
        newEvent = new Event("page");
        this.container.dispatchEvent(newEvent);
    }
    generatePagination(totalPages, currentPage, nbPageToDisplay) {
        const pagination = [];
        // Calcul du début et de la fin de la plage de pages à afficher
        const start = Math.max(1, currentPage - Math.floor(nbPageToDisplay / 2));
        const end = Math.min(totalPages, start + nbPageToDisplay - 1);
        // Ajoute les pages à afficher dans le tableau pagination
        for (let i = start; i <= end; i++) {
            pagination.push(i);
        }
        return pagination;
    }
    constRender(){
        // On vide le container
        this.container.innerHTML = "";

        // On crée le container qui va contenir "1 à 10 sur 1000 éléments"
        this.containerInfo = document.createElement("div");
        this.containerInfo.setAttribute("class", "pagination__info");

        // On crée le container de la pagination
        this.containerPagination = document.createElement("ul");
        this.containerPagination.setAttribute("class", "pagination__list");

        // On crée le bouton précédent
        this.btnPrev = document.createElement("button");
        this.btnPrev.setAttribute("class", "pagination__item p-2");
        this.btnPrev.setAttribute("type", "button");
        this.btnPrev.innerHTML = '<i class="fas fa-chevron-left"></i>';
        
        // On crée le bouton suivant
        this.btnNext = document.createElement("button");
        this.btnNext.setAttribute("class", "pagination__item p-2");
        this.btnNext.setAttribute("type", "button");
        this.btnNext.innerHTML = '<i class="fas fa-chevron-right"></i>';

        // Définition des évènements
        this.btnPrev.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.btnPrev.getAttribute("disabled") == null || this.btnPrev.getAttribute("disabled") == "false") {
                this.setPage(this.page - 1);
                this.event();
            }
        });

        this.btnNext.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.btnNext.getAttribute("disabled") == null || this.btnNext.getAttribute("disabled") == "false") {
                console.log(this.page);
                this.setPage(this.page + 1);
                console.log(this.page);
                this.event();
            }
        });

        this.render();
    }
    render(){
        // On vide le container
        this.container.innerHTML = "";

        console.log(this.nbPageTotal);
        console.log(this.page);
        firstElement = (this.page - 1) * this.pageSize + 1;
        lastElement = this.page * this.pageSize;
        // if (lastElement > this.nbPageTotal) {
        //     lastElement = this.nbPageTotal;
        // }
        this.containerInfo.innerHTML = `Affichage de ${firstElement} à ${lastElement} sur ${this.results} résultats`;
        
        // On crée la pagination, on se base sur le nombre de page total et le nombre de page à afficher
        this.containerPagination.innerHTML = "";

        // On vérifie que le nombre de page total est supérieur au nombre de page à afficher
        const pages = this.generatePagination(this.nbPageTotal, this.page, this.nbPageToDisplay);
        pages.forEach(page => {
            const li = document.createElement("li");
            li.setAttribute("class", "pagination__item");
            const btn = document.createElement("button");
            btn.setAttribute("class", "page");
            btn.setAttribute("type", "button");
            btn.innerHTML = page;
            if (page == this.page) {
                btn.setAttribute("aria-current", "page");
                li.classList.add("active");
            }
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (page != this.page) {
                    this.setPage(page);
                    this.event();
                }
            });

            li.appendChild(btn);
            this.containerPagination.appendChild(li);
        });

        // On ajoute les boutons précédent et suivant
        this.containerPagination.prepend(this.btnPrev);
        this.containerPagination.appendChild(this.btnNext);

        // Si on est à la derniere page, on désactive le bouton suivant
        if (this.page == this.nbPageTotal) {
            this.btnNext.setAttribute("disabled", "true");
        }else{
            this.btnNext.removeAttribute("disabled");
        }

        // Si on est à la première page, on désactive le bouton prev
        if (this.page == 1) {
            this.btnPrev.setAttribute("disabled", "true");
        }else{
            this.btnPrev.removeAttribute("disabled");
        }

        // On ajoute les éléments au container
        this.container.appendChild(this.containerInfo);
        this.container.appendChild(this.containerPagination);
    }
}