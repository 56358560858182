const elementEditables = document.querySelectorAll('.js-toggle-edit');
elementEditables.forEach(element => {
    // On vérifie si l'element est un input ou bouton
    if (element.tagName === 'INPUT') {
        // On double click sur la cellule pour activer la modification
        element.addEventListener("dblclick", (e) => {
            if (element.hasAttribute('readonly')) {
                element.removeAttribute('readonly');
            }
        });
    } else {
        element.addEventListener('click', (e)=> {
            let input = btn.dataset.for;
            input = document.querySelector(input);
            if (input.hasAttribute('disabled')) {
                input.removeAttribute('disabled');
            }
            if (input.hasAttribute('readonly')) {
                input.removeAttribute('readonly');
            }
        });
    }
});


