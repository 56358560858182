const JSform = document.querySelectorAll('form.js-form');
JSform.forEach(form => {

    form.addEventListener('submit', async (e)=> {
        e.preventDefault();
        e.stopPropagation();
        // const formAction = form.getAttribute('action');
        // const formMethod = form.getAttribute('method');

        // const formData = new FormData(form);

        // // Envoie de la requete
        // const loader = Notifications.Loading.fire();

        const result = await Odrazia.submit(form);

        // if (formMethod == "GET") {

        //     const response = fetch(formAction, {
        //         method: formMethod,
        //     }).then((response) => {
        //         response.json().then((data) => {
        //             loader.close();
        //             if ((response.status == 200 || response.ok)) {
        //                 Notifications.Toast.fire({
        //                     title: "Success",
        //                     text: data.message,
        //                 });
        //                 if (data.action) {
        //                     window.location.href = data.action;
        //                 }
        //             } else {
        //                 Notifications.Error.fire({
        //                     title: "Erreur",
        //                     text: data.message,
        //                 });
        //             }
        //         });
        //     });
        // }else{
        //     const response = fetch(formAction, {
        //         method: formMethod,
        //         body: formData,
        //     }).then((response) => {
        //         response.json().then((data) => {
        //             loader.close();
        //             if ((response.status == 200 || response.ok)) {
        //                 Notifications.Toast.fire({
        //                     title: "Success",
        //                     text: data.message,
        //                 });
        //                 if (data.hasOwnProperty('action') && data.action != null) {
        //                     window.location.href = data.action;
        //                 }
        //             } else {
        //                 Notifications.Error.fire({
        //                     title: "Erreur",
        //                     text: data.message,
        //                 });
        //             }
        //         });
        //     });
        // }
        

    });

    
});