const searchModal = document.querySelector('#app_search');

const globalSearch = async (formData) => {

    Notifications.Error.fire({
        title: "Erreur",
        text: "La recherche n'est pas encore implémentée",
    })

    return;
    console.log('Global Search');
    console.log(formData);
    // Mettre en cache les résultats de recherches récentes ?
    const resp = await Odrazia.get("admin_search", {limit: 10})
    const data = resp.data;
	if (resp.success && data.results) {
		console.log(data.results);
	} else {
		Notifications.Error.fire({
			title: "Erreur",
			text: "Une erreur est survenue lors de la recherche",
		})
	}

}
if (searchModal) {
    // When the search modal is opened 
    window.addEventListener('open.hs.overlay', () => {
        openSearchModal();
    });
    const openSearchModal = () => {
        // Focus search input -> already done by Preline
        // searchModal.querySelector('input[type=search]').focus(); 
    }
    searchModal.querySelector('#app_search_form').addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData(searchModal.querySelector('#app_search_form'));
        globalSearch(formData);
    });
    // INIT Shorcuts
    Mousetrap.bind(['ctrl+k', 'meta+k'], (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Define new event
        HSOverlay.open(searchModal);
    });
// console.log(searchModal);
// Mousetrap.bind(['esc'], (e) => {
//     console.log('esc');
//     if (searchModal.classList.contains('open')) {
//         e.preventDefault();
//         e.stopPropagation();
//         // Define new event
//         HSOverlay.close(searchModal);
//     }
// });

    
}

