const switchApp = document.querySelector('#selectApp');
if (switchApp) {
    /**
     * Liste des boutiques
     */
    const selectApp = switchApp.__instance;
    const getBoutiques = async () => {
        const resp = await Odrazia.get("super_admin_sites_search", {limit: 100})
        const data = resp.data;
        if (resp.success && data.results) {
            // On vient vider le select
            if (data.results.length > 0) {
                
                let selectedApp = selectApp.getSelected();
                // On vient ajouter les options sur le select
                const options = data.results.map(option => {
                    return {
                        text: option.name,
                        value: option.id,
                        selected: selectedApp && selectedApp.includes(option.code),
                        data: {
                            code: option.code
                        }
                    }
                })

                // On les range par ordre alphabétique
                options.sort((a, b) => {
                    return a.text.localeCompare(b.text)
                })

                // let oldOptions = selectApp.getData();

                // // on fait un merge des oldOption
                // oldOptions.forEach(oldOption => {
                //     // On vérifie que l'option n'est pas déjà présente dans les nouvelles options
                //     const findOption = options.find(option => option.value === oldOption.value)
                //     if (findOption === undefined) {
                //         options.unshift(oldOption)
                //     }
                // });

                selectApp.setData(options)
            } else {
                // On ajoute un message pour dire qu'il n'y a pas de catégorie
                selectApp.setData([])
            }
            initSwitchApp();
        } else {
            Notifications.Error.fire({
                title: "Erreur",
                text: "Impossible de récupérer les boutiques",
            })
        }
    }

    const initSwitchApp = async () => {
        switchApp.addEventListener('change', async (e) => {
            const idSelectedApp = selectApp.getSelected()[0];
            if (idSelectedApp) {
                const resp = await Odrazia.post("super_admin_sites_redirect_admin", {id: idSelectedApp})
                if (resp.success) {
                    // On récupère l'url actuelle
                    const path = window.location.pathname;
                    // On split l'url pour récupérer le path
                    const splitUrl = path.split('/');
                    
                    if (splitUrl[0] === "") {
                        splitUrl.shift();
                    }
                    // On récupère uniquement les 2 premiers éléments
                    const newPath = splitUrl.slice(0, 2).join('/');

                    window.location.href = window.location.origin + '/' + newPath;
                }
            }
        });
    }

    getBoutiques();
}
