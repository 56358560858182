
Odrazia = (function () {
    const newResponse = (response, data, UiNotification = true) => {
        // const detail = {};
        // if (data.hasOwnProperty('status')) {
        //     detail.status = data.status;
        // }
        // if (data.hasOwnProperty('success')) {
        //     detail.success = data.success;
        // }
        // if (data.hasOwnProperty('code')) {
        //     detail.code = data.code;
        // }
        // if (data.hasOwnProperty('message')) {
        //     detail.message = data.message;
        // }
        // // On emet un event pour les autres modules
        // const event = new CustomEvent(routeName, { detail: detail });

        // window.dispatchEvent(event);

        if ((response.status == 200 || response.ok)) {
            if (UiNotification) {
                Notifications.Toast.fire({
                    title: "Success",
                    text: data.message,
                });
            }
            if (data.hasOwnProperty('action') && data.action != null) {
                window.location.href = data.action;
            }
            return data;
        }else {
            Notifications.Error.fire({
                title: "Erreur",
                text: data.message,
            });
            return [];
        }
    }
    const logger = (method, routeName, routeOptions) => {
        console.log("Odrazia API: "+method +" "+ routeName, routeOptions);
    }
    const get = async (routeName, routeOptions = {}, UiNotification = false) => {
        try {
            logger('GET', routeName, routeOptions);
            if (UiNotification) {
                var loader = Notifications.Loading.fire({});
            }
            const response = await fetch(Routing.generate(routeName, routeOptions), {
                method: 'GET',
            });
    
            if (UiNotification) {
                loader.close();
            }
    
            const data = await response.json();
    
            return newResponse(response, data, UiNotification);
        } catch (error) {
            console.log(error);
        }
        
    }
    const post = async (routeName, routeOptions = {}, bodyData = {}, UiNotification = false) => {
        try {
            if (UiNotification) {
                var loader = Notifications.Loading.fire({});
            }

            // On vérifie si le bodyData est un objet de type FormData
            if (!(bodyData instanceof FormData)) {
                const formData = new FormData();
                // Convertion de l'objet en FormData
                [...Object.entries(bodyData)].forEach(([key, value]) => {
                    formData.append(key, value);
                });
                bodyData = formData;
            }

            logger('POST', routeName, [routeOptions, bodyData]);
            
            const response = await fetch(Routing.generate(routeName, routeOptions), {
                method: 'POST',
                body: bodyData,
            });
    
            if (UiNotification) {
                loader.close();
            }
    
            const data = await response.json();
    
            return newResponse(response, data, UiNotification);
        } catch (error) {
            console.log(error);
        }
        
    }
    const deleteElement = async (routeName, routeOptions = {}, UiNotification = true) => {
        const Confirm = await Notifications.Confirm.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible !",
        });

        if (Confirm.isConfirmed) {
            logger('DELETE', routeName, routeOptions);
            if (UiNotification) {
                var loader = Notifications.Loading.fire({
                    title: 'Suppression...',
                });
            }
            const response = await fetch(Routing.generate(routeName, routeOptions), {
                method: 'DELETE',
            });
    
            if (UiNotification) {
                loader.close();
            }
    
            const data = await response.json();
    
            return newResponse(response, data, UiNotification);
        }else{
            return {success: false, message: 'Action annulée'};
        }
    }
    const remove = async (routeName, routeOptions = {}, bodyData = {} , UiNotification = true) => {
        const Confirm = await Notifications.Confirm.fire({
            title: "Retirer cet élément ?",
            text: "",
        });

        if (Confirm.isConfirmed) {
            return post(routeName, routeOptions, bodyData, UiNotification)
        }else{
            return {success: false, message: 'Action annulée'};
        }
    }
    const submit = async (form, UiNotification = true) => {
        try {
            const formAction = form.getAttribute('action');
            const formMethod = form.getAttribute('method') ?? 'POST';

            const formData = new FormData(form);

            logger('FORM in '+formMethod, formAction, formData);

            if (UiNotification) {
                var loader = Notifications.Loading.fire({});
            }

            // Envoie de la requete
            const response = await fetch(formAction, {
                method: formMethod,
                body: formData,
            });

            if (UiNotification) {
                loader.close();
            }

            const data = await response.json();

            // On emet un event pour les autres modules
            const detail = {};
            if (data.hasOwnProperty('status')) {
                detail.status = data.status;
            }
            if (data.hasOwnProperty('success')) {
                detail.success = data.success;
            }
            if (data.hasOwnProperty('code')) {
                detail.code = data.code;
            }
            if (data.hasOwnProperty('message')) {
                detail.message = data.message;
            }
            if (data.hasOwnProperty('data')) {
                detail.data = data.data;
            }
            // On emet un event pour les autres modules
            const event = new CustomEvent('submitted', { detail: detail });

            form.dispatchEvent(event);

            return newResponse(response, data, UiNotification);
        } catch (error) {
            console.log(error);
        }
    }

    
    // const post = Swal.mixin({
    //     toast: true,
    //     icon: 'success',
    //     position: 'top-end',
    //     showConfirmButton: false,
    //     timer: 2000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //         toast.addEventListener('click', Swal.close)
    //     }
    // });
    return { 
        get: get,
        post: post,
        delete: deleteElement,
        remove: remove,
        submit: submit,
        // post: post,
    };
})();

  