class OdraziaTable {
    // constructor(tableIndex, tableColumns, tableOptions = {}, searchForm, basePath, actions = ['edit', 'delete'], pageSize = 50) {
    constructor(settings) {
        this.tableIndex = settings.tableIndex; // DOM Element
        this.tableColumns = settings.tableColumns; // Array of columns
        this.tableOptions = settings.tableOptions ?? {}; // Object option
        this.searchForm = settings.searchForm ?? null; // Search form
        this.basePath = settings.basePath; // Base path
        this.actions = settings.actions ?? ['edit', 'delete']; // Actions
        this.multiActions = settings.multiActions ?? []; // Multi actions
        this.multiActionsContainer = null; // Multi actions Container
        this.pageSize = settings.pageSize ?? 50; // Number of rows per page
        this.history = settings.history && settings.history === true ? true : false; // History 
        this.table = null;

        // AFTER IMPLEMENTING MULTI-SELECT
        // REMOVE THIS LINE
        // On retire multi-select du tableau des actions
        // if(this.actions.includes('multi-select')){
        //     this.actions = this.actions.filter((action) => action !== 'multi-select');
        //     console.log("Odraziatable.js : multi-select not yet implemented !");
        // } 
    }
    init() {
        if (!this.tableIndex) {
            console.log('Table index not found for : ' + this.tableIndex);
            console.log('Base path info : ' + this.basePath);
        }
        // On vérifie que la table n'est pas déjà instanciée
        if (!this.tableIndex.__instance) {
            // On ajoute les boutons actions
            if (this.actions.length > 0) {

                // Multi select
                if (this.actions.includes('multi-select')) {
                    // On ajoute une collonne avec une checkbox
                    this.tableColumns.unshift({
                        data: null,
                        title: `<input id="${this.tableIndex.getAttribute('id', 'table')}-selectAll" type="checkbox" class="js-selectAll">`,
                        orderable: false,
                        visible: true,
                        width: '50px',
                        createdCell: (cell, cellData, rowData, rowIndex, colIndex) => {
                            // Créer un bouton pour actions
                            // On crée le container
                            const container = document.createElement("div");
                            container.classList.add(...['flex', 'gap-2']);
                            // Edit Button
                            const checkbox = document.createElement("input");
                            checkbox.classList.add(...['field__checkbox']);
                            checkbox.setAttribute("type", "checkbox");
                            checkbox.setAttribute("name", "select[]");
                            checkbox.setAttribute("value", rowData.id);
                            checkbox.addEventListener("click", (e) => {
                                e.stopPropagation();
                                this.select(rowData, checkbox.checked)
                            });
                            container.appendChild(checkbox);

                            cell.innerHTML = '';
                            cell.appendChild(checkbox);
                            
                            cell.addEventListener("click", (e) => {
                                e.stopPropagation();
                                checkbox.click();
                            });
                        },
                    });

                    // On crée des boutons pour les actions multiples
                    if (this.multiActions && this.multiActions.length > 0) {
                        this.multiActionsContainer = document.createElement("div");
                        
                        this.multiActionsContainer.classList.add(...['btn-group', 'justify-end', 'hidden']);
                        this.multiActions.forEach((action) => {
                            const btn = document.createElement("button");
                            // On split la string de class
                            let tabClass = action.class.split(' ');
                            btn.classList.add(...tabClass);
                            btn.innerHTML = action.text;
                            btn.type = "button";
                            btn.addEventListener("click", (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                action.action(this.table);
                            });
                            this.multiActionsContainer.appendChild(btn);
                        });
                        // On ajoute le container des actions multiples
                        this.tableIndex.parentNode.insertBefore(this.multiActionsContainer, this.tableIndex);
                    }
                }

                // On vérifie que la colonne actions n'existe pas déjà
                const actionsColumn = this.tableColumns.find((column) => column.title === 'Actions');
                if (!actionsColumn) {
                    this.tableColumns.push({
                        data: null,
                        title: 'Actions',
                        orderable: false,
                        width: '100px',
                        createdCell: (cell, cellData, rowData, rowIndex, colIndex) => {},
                        render: () => ''
                    });
                }

                // On récupère la position de la colonne actions
                const index = this.tableColumns.findIndex((column) => column.title === 'Actions');
                // On ajoute les boutons actions
                this.tableColumns[index].createdCell = (cell, cellData, rowData, rowIndex, colIndex) => {
                    // Créer un bouton pour actions d
                    // On crée le container
                    const container = document.createElement("div");
                    container.classList.add(...['flex', 'gap-2']);
                    // Edit Button
                    if (this.actions.includes('edit') || this.actions.includes('edit-external')) {
                        const btnEdit = document.createElement("a");
                        btnEdit.classList.add(...['btn', 'btn-sm', 'btn-info']);
                        btnEdit.setAttribute("href", this.edit(rowData));
                        btnEdit.innerHTML = '<i class="fas fa-pen"></i>';
                        
                        if (this.actions.includes('edit-external')) {
                            btnEdit.setAttribute("target", "_blank");
                        }

                        container.appendChild(btnEdit);
                    }

                    // Update Button
                    if (this.actions.includes('update')) {
                        const btnUpdate = document.createElement("button");
                        btnUpdate.classList.add(...['btn', 'btn-sm', 'btn-secondary']);
                        btnUpdate.setAttribute("type", "button");
                        btnUpdate.innerHTML = '<i class="fas fa-floppy-disk"></i>';
                        btnUpdate.addEventListener("click", async (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.update(rowData);
                        });

                        container.appendChild(btnUpdate);
                    }

                    // Remove Button
                    if (this.actions.includes('remove')) {
                        const btnRemove = document.createElement("button");
                        btnRemove.classList.add(...['btn', 'btn-sm', 'btn-danger']);
                        btnRemove.setAttribute("type", "button");
                        btnRemove.innerHTML = '<i class="fas fa-xmark"></i>';
                        btnRemove.addEventListener("click", async (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.remove(rowData);
                        });

                        container.appendChild(btnRemove);
                    }

                    // Delete Button
                    if (this.actions.includes('delete')) {
                        const btnDelete = document.createElement("button");
                        btnDelete.classList.add(...['btn', 'btn-sm', 'btn-danger']);
                        btnDelete.setAttribute("type", "button");
                        btnDelete.innerHTML = '<i class="fas fa-trash"></i>';
                        btnDelete.addEventListener("click", async (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.delete(rowData);
                        });

                        container.appendChild(btnDelete);
                    }

                
        
                    cell.appendChild(container);
                };
            }

            // On vérifie si les colonnes définis sont éditables
            this.tableColumns.forEach((column) => {
                if (column.editable && column.editable === true) {
                    column.createdCell = (cell, cellData, rowData, rowIndex, colIndex) => {
                        // On crée un input
                        const input = document.createElement("input");
                        input.setAttribute("id", `${rowData.id}-${column.data}`);

                        const enableTypes = ['text', 'number', 'checkbox'];
                        if (!column.type || !enableTypes.includes(column.type) ) {
                            column.type = 'text';
                        }

                        if (column.type === 'text' || column.type === 'number') {
                            input.setAttribute("type", column.type);
                            input.setAttribute("value", cellData || '');
                            
                            input.addEventListener("input", (e) => {
                                rowData[column.data] = input.value;
                            });
                            input.classList.add('field');
                        }

                        if (column.type === 'checkbox') {
                            input.setAttribute("type", 'checkbox');
                            input.setAttribute("value", "true");
                            input.checked = cellData;
                            input.addEventListener("change", (e) => {
                                rowData[column.data] = input.checked;
                            });
                            input.classList.add('field__checkbox');
                        }

                        // On vérifie si la colonne est la reference
                        if (column.data === 'reference') {
                            input.setAttribute("readonly", true);
                            input.setAttribute("title", "Double click to edit");

                            // On double click sur la cellule pour activer la modification
                            input.addEventListener("dblclick", (e) => {
                                input.removeAttribute("readonly");
                            });
                        }

                        // On vérifie si un event est défini
                        if (column.addEventListener) {
                            input.addEventListener(column.addEventListener.event, column.addEventListener.function);
                        }

                        cell.innerHTML = '';
                        cell.appendChild(input);
                    };
                }

                // On vérifie si la colonne est searchable
                column.searchable = column.searchable && (column.searchable === true || column.searchable === 'true') ? true : false;
            });

            // On instancie le datatable
            this.table = new DataTable(this.tableIndex, {
                paging: true,
                searching: false,
                pageLength: this.pageSize,
                lengthChange: false,
                processing: true,
                serverSide: true,
                autoWidth: false,
                scrollX: true,
                select: {
                    style: 'api',
                    info: false,
                },
                fixedColumns: {
                    left: 0,
                    right: 1
                },
                language: {
                    url: `//cdn.datatables.net/plug-ins/1.13.7/i18n/fr-FR.json`
                },
                pagingType: 'numbers',
                columns: this.tableColumns,
                ajax: async (data, callback, settings) => {
                    // console.log('DRAW NB');
                    // console.log(settings.iDraw);
                    const nbDraw = settings.iDraw;
                    // Recup les sort
                    let sort = {};
                    for (const key in data.order) {
                        const item = data.order[key];
                        const column = data.columns[item.column];
                        if (column.orderable) {
                            sort[column.data] = (item.dir).toUpperCase();
                        }
                    }

                    const filters = {};
            
                    if (this.searchForm != null) {
                        formData = new FormData(this.searchForm);
                        // On récupère les filtres dans les query params
                        if (this.history && nbDraw === 1) {
                            const url = new URL(window.location.href);
                            url.searchParams.forEach((value, key) => {
                                if (key.includes('[]')) {
                                    const nameKey = key.replace('[]', '');
                                    if (!filters[nameKey]) {
                                        filters[nameKey] = [];
                                    }
                                    filters[nameKey].push(value);
                                }else{
                                    filters[key] = value;
                                }
                            });
                        }
                    }else{
                        formData = new FormData();
                    }
                    formData.set('limit', data.length);
                    const page = data.start > 0 ? data.start / data.length : 0;
                    formData.set('page', page + 1);
                    if (data.sort && (data.sort !== undefined && data.sort !== "undefined" && data.sort !== null)) {
                        formData.set('sort', data.sort);
                    }

                    // const filters = formData;
                    const newFilters = {};
                    [...formData.entries()].forEach(element => {
                        // console.log(element);
                        // On vérifie si l'élément est un tableau
                        if (element[0].includes('[]')) {
                            const key = element[0].replace('[]', '');
                            if (!newFilters[key]) {
                                newFilters[key] = [];
                            }
                            // On vérifie si la valeur est déjà dans le tableau
                            if (!newFilters[key].includes(element[1])) {
                                newFilters[key].push(element[1]);
                            }
                            return;
                        }else{
                            newFilters[element[0]] = element[1];
                        }
                    });

                    // On remplace les ancien filtres par les nouveaux, on écrase les anciens
                    for (const key in newFilters) {
                        filters[key] = newFilters[key];
                    }

                    // On ajoute les filtres de recherche dans l'url et on l'ajout à l'historique du navigateur
                    if (this.searchForm != null && this.history) {
                        const url = new URL(window.location.href);
                        // On vide les query params
                        url.search = '';
                        for (const key in filters) {
                            if (Array.isArray(filters[key])) {
                                filters[key].forEach((value) => {
                                    url.searchParams.append(`${key}[]`, value);
                                });
                            }else{
                                url.searchParams.append(key, filters[key]);
                            }
                        }
                        window.history.pushState({}, '', url);
                    }

                    const response = await Odrazia.get(this.basePath+'_search', filters);
                    const result = response.data;

                    // On déclenche l'event draw
                    const event = new Event('draw');
                    this.tableIndex.dispatchEvent(event);
                    callback({
                        data: result.results,
                        recordsTotal: result.total,
                        recordsFiltered: result.total,
                    });
                },
                initComplete: () => {
                    const columns = this.tableColumns;
                    const searchForm = this.searchForm;
                    this.table
                        .columns()
                        .every(function () {
                            let column = this;
                            let colConfig = columns[column.index()];
                            // On vérifie si la colonne est visible et si elle est "searchable"
                            if (column.visible() && colConfig.searchable && searchForm) {
                                let title = column.header().textContent;
                                
                                // let label = document.createElement('label');
                                // label.textContent = title;
                                // label.classList.add('text-bold', 'text-sm');
                                // Create input element
                                let input = document.createElement('input');
                                input.setAttribute('type', 'text');
                                input.classList.add('w-full');
                                input.setAttribute('name', colConfig.data ?? title.toLowerCase());
                                input.placeholder = title;

                                // On ajoute le label et l'input dans la colonne
                                column.header().replaceChildren(input);

                                // column.header().innerHTML = '';
                                // column.header().appendChild(label);
                                // column.header().appendChild(input);

                                // On vérifie si la valeur est déjà présente dans les query params
                                const url = new URL(window.location.href);
                                const value = url.searchParams.get(input.name);
                                if (value) {
                                    input.value = value;

                                    // On ajoute un input hidden pour la recherche
                                    const inputHidden = searchForm.querySelector(`input[name="${input.name}"]`) ?? document.createElement('input');
                                    inputHidden.setAttribute('value', input.value);
                                    inputHidden.setAttribute('type', 'hidden');
                                    inputHidden.setAttribute('name', input.name);
                                    searchForm.appendChild(inputHidden);
                                }
                
                                // Event listener for user input
                                input.__event = null;
                                input.addEventListener('keyup', () => {
                                    clearTimeout(input.__event);
                                    input.__event = setTimeout(() => {
                                        // On ajoute un input hidden pour la recherche
                                        const inputHidden = searchForm.querySelector(`input[name="${input.name}"]`) ?? document.createElement('input');
                                        inputHidden.setAttribute('value', input.value);
                                        
                                        if (inputHidden.name !== input.name) {
                                            inputHidden.setAttribute('type', 'hidden');
                                            inputHidden.setAttribute('name', input.name);
                                            searchForm.appendChild(inputHidden);
                                        }
                                        searchForm.dispatchEvent(new Event('submit',{cancelable: true} ));
                                    }, 1500);
                                });
                            }
                        });
                },
                ...this.tableOptions,
            });

            // On save l'objet sur le DOM
            this.tableIndex.__instance = this.table;
            // On définie les events
            this.initEvents();
            return this;
        }
    }
    initEvents = () => {
        // window.addEventListener('resize', () => {
        //     this.enableFixedColumn();
        // });
        this.searchForm.addEventListener("submit", (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.table.draw();
        });

        if (this.actions.includes('multi-select')) {
            $(this.tableIndex).on('init.dt', (e) => {
                const inputSelectAll = document.querySelector(`#${this.tableIndex.getAttribute('id', 'table')}-selectAll`);

                if(inputSelectAll){
                    inputSelectAll.addEventListener("click", (e) => {
                        e.stopPropagation();
                        this.selectAll(inputSelectAll.checked);
                    });
                }
            });

            this.tableIndex.addEventListener("multi-select", (e) => {
                // e.preventDefault();
                // e.stopPropagation();
                this.toggleActionsMultiSelect();
            });
        }
    }
    enableFixedColumn = () => {
        if (this.tableColumns.fixedColumns()) {
            if (window.innerWidth < 768) {
                this.tableColumns.fixedColumns().right(0);
            } else {
                this.tableColumns.fixedColumns().right(1);
            }
        }
    };

    /**
     * Les actions par default
     */

    edit = (rowData) => {
        return Routing.generate(this.basePath+'_fiche', {id: rowData.id});
    }

    update = async (rowData) => {
        const result = await Odrazia.post(this.basePath+'_update', {id: rowData.id}, rowData);
        if (result.success) {
            Notifications.Toast.fire({
                title: "Success",
                text: result.message ?? "Modification effectuée",
            });
        }
    }

    remove = async (rowData) => {
        const result = await Odrazia.remove(this.basePath+'_remove', {id: rowData.id});
        if (result.success) {
            this.table.draw();
        }
    }

    delete = async (rowData) => {
        const result = await Odrazia.delete(this.basePath+'_delete', {id: rowData.id});
        if (result.success) {
            this.table.draw();
        }
    }

    select = (rowData, checked) => {
        // Si le checked est true, on ajoute l'id dans le tableau
        // Sinon on le supprime
        // console.log(rowData);
        if (checked) {
            this.table.row((idx, data) => data.id === rowData.id).select();
        }else {
            this.table.row((idx, data) => data.id === rowData.id).deselect();
        }

        // On dispatch un event pour les checkbox
        this.tableIndex.dispatchEvent(new Event('multi-select'));
    }

    selectAll = (checked) => {
        if (checked) {
            this.table.rows().select();
            // On set les checkbox à true
            
        }else {
            this.table.rows().deselect();
        }

        const inputs = this.tableIndex.querySelectorAll('input[name="select[]"]');
        inputs.forEach((input) => {
            input.checked = checked;
        });

        // On dispatch un event pour les checkbox
        this.tableIndex.dispatchEvent(new Event('multi-select'));
    }

    toggleActionsMultiSelect = () => {
        // On récupère les valeurs selectionnées
        const selectedRows = this.table.rows({selected: true}).data();
        if (selectedRows.length > 0) {
            // On affiche les boutons
            this.multiActionsContainer.classList.remove('hidden');
        }else{
            // On cache les boutons
            this.multiActionsContainer.classList.add('hidden');
        }
    }
}