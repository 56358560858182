var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'link'],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }], 
    [{ 'align': [] }],
    ['clean']                                         
];
var options = {
    // debug: 'info',
    modules: {
      toolbar: toolbarOptions
    },
    placeholder: 'Description...',
    theme: 'snow'
};


const textEditors = document.querySelectorAll('.js-editor');
// textEditors.forEach(textEditor => {
//     let defaultContent = textEditor.getAttribute('data-content') || '';
//     let editor = new Quill(textEditor, options);
//     editor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
//         const plaintext = node.innerText
//         const Delta = Quill.import('delta')
//         return new Delta().insert(plaintext)
//     });

//     editor.setContents([
//         { insert: defaultContent },
//     ]);
//     // editor.insertText(0, defaultContent);
//     editor.root.innerHTML = defaultContent;
//     // On créer un input hidden pour stocker le contenu du text editor
//     let input = document.createElement('input');
//     input.type = 'hidden';
//     input.name = textEditor.getAttribute('data-name');
//     input.value = defaultContent;
//     textEditor.parentNode.appendChild(input);
//     textEditor.__instance = editor;

//     editor.on('text-change', (delta, oldDelta, source) => {
//         input.value = editor.root.innerHTML;
//     });
// });

TextEditor = (function () {
    const newTextEditor = (textEditor) => {
        let defaultContent = textEditor.getAttribute('data-content') || '';
        let editor = new Quill(textEditor, options);
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
            const plaintext = node.innerText
            const Delta = Quill.import('delta')
            return new Delta().insert(plaintext)
        });

        editor.setContents([
            { insert: defaultContent },
        ]);
        // editor.insertText(0, defaultContent);
        editor.root.innerHTML = defaultContent;
        // On créer un input hidden pour stocker le contenu du text editor
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = textEditor.getAttribute('data-name');
        input.value = defaultContent;
        textEditor.parentNode.appendChild(input);
        textEditor.__instance = editor;

        editor.on('text-change', (delta, oldDelta, source) => {
            input.value = editor.root.innerHTML;
        });
    }
    return { 
        new: newTextEditor
    };
})();

textEditors.forEach(textEditor => {
    TextEditor.new(textEditor);
});