// On vérifie que l'url ne contient pas une ancre d'un tab
const url = window.location.href;
const tab = url.split('#')[1];
if (tab) {
    // On vérifie que l'ancre est bien un tab

    // data-hs-tab="#card-type-tab-1"
    // const tabPanelElement = document.querySelector(`#${tab}[role="tabpanel"]`);
    const tabElement = document.querySelector(`[data-hs-tab="#${tab}"]`);
    HSTabs.open(tabElement);
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, 0);

    // if (tabElement) {
    //     // On vient récupérer la tab active
    //     const parentTabElement = tabElement.closest('.tablist');
    //     const tabElementActive = parentTabElement.querySelector(`.active[data-hs-tab]`);
    // }

    

    // if (tabPanelElement) {
    //     // On récupère l'instance du tab
    //     const tabPanel = HSTabs.getInstance(tabPanelElement);
    //     console.log(HSTabs);
    //     console.log(tabPanel);
    //     console.log(tabPanel);
    //     if (tabPanel) {
    //         // Si existant on ouvre la tab
    //     }
    // }
}

// Scroll automatique vers le hat de la tab active
window.addEventListener('change.hs.tab', (tabToggleEl) => {
    const tab = tabToggleEl.target;
    // On vérifie qu'on est en haut de la tab qui devient active, si ce n'est pas le cas on scroll vers le haut de la tab
    const tabToggleElBottom = tab.getBoundingClientRect().bottom;
    const tabContentElTop = document.querySelector(tab.getAttribute('data-hs-tab')).getBoundingClientRect().top;

    // On vient set dans l'url l'ancre de la tab active
    const url = window.location.href;
    const urlSplit = url.split('#');
    let newUrl = urlSplit[0];
    newUrl += `${tabToggleEl.target.getAttribute('data-hs-tab')}`;

    window.history.replaceState({}, '', newUrl);
    // 16px pour le gap 
    if (tabToggleElBottom > tabContentElTop + 16) {
        let spacing = (tabContentElTop * -1) + tabToggleElBottom + 16 ;
        // get position y of window 
        let y = window.scrollY - spacing;
        if (spacing < 0) {
            spacing *= -1;
        }
        window.scrollTo({
            top: y,
            behavior: "smooth",
        });
    }
})