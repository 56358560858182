console.log('Global JS');



/**
 * EXPORT
 */
const btnExport = document.querySelectorAll(".js-btn-export");
btnExport.forEach(btn => {
    btn.addEventListener("click", async (e) => {
        e.preventDefault();
        const url = btn.getAttribute("href") ?? btn.getAttribute("data-url");

        if (url) {
            if (btn.hasAttribute("data-use-query-params")) {
                // On récupère les query params
                const params = new URLSearchParams(window.location.search);
                // On ouvre un nouvel onglet
                window.open(url + "?" + params.toString(), "_blank");
            }else{
                // On ouvre un nouvel onglet
                window.open(url, "_blank");
            }
        }else{
            console.error("Impossible de récupérer l'url de l'export");
        }
        
    })
})

/**
 * Languages
 */
const selectLanguages = document.querySelectorAll("select.js-select-language");
selectLanguages.forEach(selectLanguage => {
    selectLanguage.addEventListener("change", async (e) => {
        const datafor = selectLanguage.getAttribute("data-for");
        const value = selectLanguage.value;
        
        // On récupère tous les éléments qui ont un id qui commence par datafor
        const elements = document.querySelectorAll(`[id^="${datafor}"]`);
        elements.forEach(element => {
            element.classList.add("hidden");
        })

        // On récupère l'élément qui a l'id qui correspond à la valeur du select
        const elementsExact = document.querySelectorAll(`#${datafor}-${value}`);
        elementsExact.forEach(element => {
            element.classList.remove("hidden");
        })
    })
})

/**
 * Liste des statuts
 */
const selectStatus = document.querySelectorAll("select.js-select-status");
const getStatuts = async () => {
	const resp = await Odrazia.get("shop_orders_status_search", {limit: 10})
    const data = resp.data;
	if (resp.success && data.results) {

        selectStatus.forEach(selectStatu => {
            const select = selectStatu.__instance;

            if (data.results.length > 0) {
			
                const selectedCategoriesIds = select.getSelected().map((id) => parseInt(id))
                options = generateTabStatus(data.results, selectedCategoriesIds)
    
                select.setData(options)
            } else {
                select.setData([])
            }
        });
		
	} else {
		Notifications.Error.fire({
			title: "Erreur",
			text: "Impossible de récupérer les status des commandes",
		})
	}
}
const generateTabStatus = (elements, selectedElements, i = 1) => {
	const returnTab = []
	elements.forEach(element => {
		const option = {
			text: element.name,
			value: element.id,
            html: `<span class="badge" style="background-color: ${element.color};">${element.name}</span>`,
            selected: selectedElements.includes(element.id)
		}
		returnTab.push(option)
	})
	return returnTab
}

if (selectStatus.length > 0) {
    window.addEventListener("load", () => {
        getStatuts()
    })
}
